import React from 'react';
import '../css/Summary.css'; // Optional, for any additional custom styles

const Summary = ({ icon, title, value }) => {

    return (
        <>
            <div className="card summary-card">
                <div className="card-icon" style={{ backgroundColor: icon.backgroundColor }}>
                    <i className={icon.className} style={{ color: icon.color }}></i>
                </div>
                <div className="card-content">
                    <h2>{value}</h2>
                    <h4>{title}</h4>
                </div>
            </div>
        </>
    );
};

export default Summary;
