import React, { useState } from 'react';
import '../css/WorkExperience.css';
import WorkExperienceModal from './Modals/WorkExperienceModal'; // Make sure this component is defined

const WorkExperience = ({ experiences }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalTechnologies, setModalTechnologies] = useState(null);

  const handleClick = (title, details, technologies) => {
    setModalTitle(title)
    setModalData(details);
    setModalTechnologies(technologies);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="timeline-container">
        <div className="timeline">
          {experiences.map((exp, id) => (
            <div key={id} className="timeline-item">
              <div
                className="timeline-marker"
                onClick={() => handleClick(exp.company + " | " + exp.role, exp.responsibilities, exp.technologies)}
                title={exp.position}
              >
                <img src={require(`../assets/icons/${exp.logo}`)} title={exp.company} alt={exp.company}/>
              </div>
              <div className="timeline-tooltip" onClick={() => handleClick(exp.company + " | " + exp.role, exp.responsibilities, exp.technologies)}>
                <div className="timeline-content">
                  <h3 className="timeline-title">{exp.position}</h3>
                  <p className="timeline-date">{exp.start}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal && <WorkExperienceModal title={modalTitle} details={modalData} technologies={modalTechnologies} onClose={handleCloseModal} />}
    </>
  );
};

export default WorkExperience;
