import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faGithub} from '@fortawesome/free-brands-svg-icons';
import '../css/Projects.css';

const Projects = ({ projects }) => {
  return (
    <ul className="projects-list">
      {projects.map((project, index) => (
        <li key={index} className="project-card">
          <div className="project-content">
            <h3>{project.application}</h3>
            <p>{project.description}</p>
            <div className="tech-stack">
              {project.technologies.map((tech, idx) => (
                <div key={idx} className="tech-icon">
                  {/* {techIcons[tech] ? <FontAwesomeIcon icon={techIcons[tech]} title={tech} /> : tech} */}
                  <img src={require(`../assets/icons/tech_svg/${tech}.svg`)} alt={tech} title={tech} />
                  <span className="tooltip-text">{tech}</span>
                </div>
              ))}
            </div>
            <div className="project-links">
              <a href={project.demoLink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faExternalLinkAlt} /> Demo
              </a>
              <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} /> GitHub
              </a>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default Projects;
