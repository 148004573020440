import React from 'react';
import '../css/InterestsAndHobbies.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faBook, faTelevision, faKitchenSet, faPlane, faBasketball } from '@fortawesome/free-solid-svg-icons';

const InterestsAndHobbies = ({ interests }) => {

  const icons = [
    faCar, 
    faPlane,
    faBasketball,
    faTelevision,
    faKitchenSet,
    faBook
  ]
  return (
      <div className="hobbies-grid">
        {interests.map((interest, index) => (
          <div key={index} className="hobby-item">
            <FontAwesomeIcon icon={icons[index]} className="hobby-icon" />
            <h4>{interest.hobby}</h4>
            <p>{interest.details}</p>
        </div>
        ))}
      </div>
  );
}

export default InterestsAndHobbies;