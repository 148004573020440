import React from 'react';
import '../css/Skills.css';


const Skills = ({ skills }) => {

  // Prepare progress bar data
  const getProgressBarData = (categorySkills) => {
    // Extract skill names and values from the categorySkills
    const skillNames = Object.keys(categorySkills);
    const skillValues = Object.values(categorySkills);

    return { skillNames, skillValues };
  };

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const faIconsArray = [
    "code", "laptop-code", "microchip", "database", "shield", "desktop", "code-branch", "brain", "laptop", "pen-ruler", "arrows", "gamepad"
  ]
  return (
    <div className="skills-container">
      {Object.keys(skills[0]).map((category, index) => {
        const { skillNames, skillValues } = getProgressBarData(skills[0][category]);
        return (
          <div key={index} className="skill-card">
            <div className="card-inner">
              {/* Front Side */}
              <div className="card-front">
                <h3>
                  <i className={`fas fa-${faIconsArray[index]}`}></i> {/* Font Awesome Icon */}
                  <br/>
                  {titleCase(category.replace(/_/g, ' '))}
                </h3>
              </div>
              {/* Back Side */}
              <div className="card-back">
                <div className="skills-list">
                  {skillNames.map((skill, idx) => (
                    <div key={idx} className="skill-item">
                      <span>{skill}</span>
                      <div className="progress-bar">
                        <div
                          className="progress"
                          style={{ width: `${skillValues[idx] * 10}%` }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Skills;
