import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ProfessionalSummary from './components/ProfessionalSummary';
import Skills from './components/Skills';
import WorkExperience from './components/WorkExperience';
import Certifications from './components/Certifications';
import Education from './components/Education';
import Projects from './components/Projects';
import InterestsAndHobbies from './components/InterestsAndHobbies';
import LearningJourney from './components/LearningJourney';
import MobileViewWorkExperience from './components/mobileView/MobileViewWorkExperience';
import MobileEducation from './components/mobileView/MobileEducation';
import MobileCertifications from './components/mobileView/MobileCertifications';
import './App.css';
import resumeData from './constants/resumeData.json';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 810);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 810);
    };

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <section className="portfolio-section" style={{ marginTop: '170px' }}>
                    <div className="portfolio-section-header">
                      <h3>Summary</h3>
                    </div>
                    <ProfessionalSummary data={resumeData} />
                  </section>

                  <section className="portfolio-section">
                    <div className="portfolio-section-header">
                      <h3>Work Experience</h3>

                    </div>
                    <p className="portfolio-section-subheader">Click on each section to view detailed roles & responsibilities</p>
                    {isMobile ? (
                      <MobileViewWorkExperience experiences={resumeData.work_experience} />
                    ) : (
                      <WorkExperience experiences={resumeData.work_experience} />
                    )}
                  </section>

                  <section className="portfolio-section">
                    <div className="portfolio-section-header">
                      <h3>Self-Developed Apps</h3>
                    </div>
                    <Projects projects={resumeData.self_projects} />
                  </section>

                  <section className="portfolio-section">
                    <div className="portfolio-section-header">
                      <h3>Skill Set</h3>
                    </div>
                    <p className="portfolio-section-subheader">
                      Click each card to reveal detailed skills and strengths under each category.
                    </p>
                    <Skills skills={resumeData.skills} />
                  </section>

                  <section className="portfolio-section">
                    <div className="portfolio-section-header">
                      <h3>Learning Journey</h3>
                    </div>
                    <LearningJourney
                      learning_journey={resumeData.learning_journey}
                      future_goal={resumeData.future_goal}
                    />
                  </section>

                  <section className="portfolio-section">
                    <div className="portfolio-section-header">
                      <h3>Certifications</h3>
                    </div>
<p className="portfolio-section-subheader">
    Expand each section below to view certification details.
</p>
                    {isMobile ? (
                      <MobileCertifications certifications={resumeData.certifications} />
                    ) : (
                      <Certifications certifications={resumeData.certifications} />
                    )}
                  </section>

                  <section className="portfolio-section">
                    <div className="portfolio-section-header">
                      <h3>Academics</h3>
                    </div>
                    {isMobile ? (
                      <MobileEducation education={resumeData.education} />
                    ) : (
                      <Education education={resumeData.education} />
                    )}
                  </section>

                  <section className="portfolio-section">
                    <div className="portfolio-section-header">
                      <h3>Interests & Hobbies</h3>
                    </div>
                    <InterestsAndHobbies interests={resumeData.interests} />
                  </section>
                </>
              }
            />

            {/* Redirect any other paths to the home page */}
            <Route
              path="*"
              element={<Navigate to="/" />}
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
