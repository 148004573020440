import React from 'react';
import '../../css/MobileEducation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faSchool } from '@fortawesome/free-solid-svg-icons';

const MobileEducation = ({ education }) => {
  return (
    <div className="mobile-timeline-container">
      {education.map((item, index) => (
        <div key={index} className="mobile-timeline-item">
          <div className="mobile-timeline-marker">
            <FontAwesomeIcon 
              className='icon'
              icon={item.degree.includes("B.Tech") ? faGraduationCap : faSchool} 
              size="2x" 
            />
          </div>
          <div className="mobile-timeline-content">
            <h3 style={{ fontSize: '1.2rem' }} className="mobile-timeline-title">{item.degree} ({item.specialization})</h3>
            <p style={{ fontSize: '1rem' }} className="mobile-timeline-date">{item.year}</p>
            <p style={{ color: '#d6d6d6' }}className="mobile-timeline-description">
              {item.institution}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MobileEducation;
