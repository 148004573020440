
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import '../css/Certifications.css';

const Certifications = ({ certifications }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleClick = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className="certifications-container">
      {certifications.map((cert, index) => (
        <div
          key={index}
          className={`certification-item ${expandedIndex === index ? 'expanded' : ''}`}
          onClick={() => handleClick(index)}
        >
          <div key={index} className="certification-header">
          <FontAwesomeIcon icon={faCertificate} className="expand-icon" />
            <p>{cert.title}</p>
            <span className="expand-icon">{expandedIndex === index ? '−' : '+'}</span>
          </div>
          <div className="certification-details">
            {expandedIndex === index && (
              <>
                <p><strong>{cert.provider} - {cert.year} </strong></p>
                <p><strong>Description:</strong> {cert.description}</p>
                <p><strong>Skills Acquired:</strong> {cert.skills}</p>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Certifications;
