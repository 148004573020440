import React, { useState, useEffect } from 'react';
import '../css/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faInstagram, faXTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/assets/resume/Somsuvra%20Resume.pdf`;
    link.download = 'Somsuvra_Resume.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  
  return (
    <header className={`header ${isScrolled ? "header-scrolled" : ""}`}>
      <div className="header-content">
        <div className="name-title">
          <h1>Somsuvra Ganguly</h1>
          <h2>Full Stack Developer</h2>
        </div>
        <div className="contact-info">
          <a href="mailto:isganguly.95@gmail.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
          </a>
          <a href="https://www.linkedin.com/in/somsuvra-ganguly-67b95a12b/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="icon" />
          </a>
          <a href="https://github.com/bblaal/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} className="icon" />
          </a>
          <a href="https://x.com/Somsuvra_G" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXTwitter} className="icon" />
          </a>
          <a href="https://www.instagram.com/in/somsuvra_ganguly/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="icon" />
          </a>
          <button className="header-button" onClick={handleDownload}>Download Resume</button>
        </div>
      </div>
    </header>
  );
}

export default Header;
