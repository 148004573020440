import React from 'react';
import '../../css/WorkExperienceModal.css';

function WorkExperienceModal({ title, details, technologies, onClose }) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="modal-close" onClick={onClose}>X</button>
          <h3>{title}</h3>
          <ul className="modal-details-list">
            {details.map((detail, index) => (
              <li key={index} className="modal-detail-item">
                {detail}
              </li>
            ))}
            <li key="tech" className="modal-detail-item">
            <h5>Technology Used : </h5>
            {technologies.map((tech, index) => (
              tech + ", "
            ))}
            </li>
          </ul>
        </div>
      </div>
    );
  }

export default WorkExperienceModal;
