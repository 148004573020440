import React from 'react';
import '../css/LearningJourney.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faBrain, faLaptopCode, faDatabase, faDiagramProject, faRocket } from '@fortawesome/free-solid-svg-icons';

const LearningJourney = ({ learning_journey, future_goal }) => {
    const icons = [
        faCode, faLaptopCode, faDatabase, faDiagramProject, faBrain
    ]
    return (
        <>
            <div className="learning-timeline">
                {learning_journey.map((learning, index) => (
                    <div key={index} className="learning-timeline-item">
                        <div className="learning-timeline-icon">
                            <FontAwesomeIcon icon={icons[index]} />
                        </div>
                        <div className="learning-timeline-content">
                            <h4>{learning.title}</h4>
                            <p>{learning.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="future-goal">
                <h3>Future Goal</h3>
                <div className="future-goal-content">
                    <FontAwesomeIcon icon={faRocket} className="future-goal-icon" />
                    <p>{future_goal.goal}</p>
                </div>
            </div>
        </>
    );
}

export default LearningJourney;
