import React from 'react';
import '../css/ProfessionalSummary.css';
import Summary from './Summary'

const ProfessionalSummary = ({ data }) => {

  const calculateExperience = (dateOfJoining) => {
    const [day, month, year] = dateOfJoining.split('/').map(Number);
    const joinDate = new Date(year, month - 1, day); // month - 1 because months are 0-indexed
    const today = new Date();
    let years = today.getFullYear() - joinDate.getFullYear();
    let months = today.getMonth() - joinDate.getMonth();
    if (months < 0) {
      years--;
      months += 12;
    }
    return `${years}Y ${months}M`;
  }

  const totalOrgCount = (work_experience) => {
    const companySet = new Set();
    work_experience.forEach((experience) => {
      companySet.add(experience.company);
    });
    return Array.from(companySet).length;
  }

  const totalSkillsCount = (skills) => {
    return Object.values(skills[0])
      .reduce((count, category) => count + Object.keys(category).length, 0);
  };
  
  const summaryCardData = [
    {
      icon: { className: 'fas fa-briefcase', backgroundColor: '#e74c3c', color: '#fff' },
      title: "of Total Experience",
      value: calculateExperience(data.professional_summary.experience),
      percentage: '+55%',
      percentageText: 'than last week',
      percentageColor: 'green',
    },
    {
      icon: { className: 'fas fa-building', backgroundColor: '#fdc500', color: '#fff' },
      title: "Different Organizations",
      value: totalOrgCount(data.work_experience),
      percentage: '+3%',
      percentageText: 'than last month',
      percentageColor: 'green',
    },
    {
      icon: { className: 'fas fa-sitemap', backgroundColor: '#27ae60', color: '#fff' },
      title: 'Different Clients',
      value: data.work_experience.length,
      percentage: '-2%',
      percentageText: 'than yesterday',
      percentageColor: 'red',
    },
    {
      icon: { className: 'fa fa-terminal', backgroundColor: '#9a0beb', color: '#fff' },
      title: 'Techstack Learned',
      value: totalSkillsCount(data.skills),
      percentage: '+5%',
      percentageText: 'than yesterday',
      percentageColor: 'green',
    },
  ];

  return (
    <div className="summary-dashboard">
      {summaryCardData.map((card, index) => (
        <Summary key={index} {...card} />
      ))}
    </div>
  );
}

export default ProfessionalSummary;
