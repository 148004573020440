import React from 'react';
import '../css/Education.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faSchool } from '@fortawesome/free-solid-svg-icons';

const Education = ({ education }) => {
  return (
      <div className="timeline-container">
        <div className="timeline">
          {education.map((item, index) => (
            <div key={index} className="timeline-item">
              <div className="timeline-marker">
                <FontAwesomeIcon 
                  icon={item.degree.includes("B.Tech") ? faGraduationCap : faSchool} 
                  size="2x" 
                />
              </div>
              <div className="timeline-tooltip">
                <div className="timeline-content">
                  <h3 className="timeline-title">{item.degree} ({item.specialization})</h3>
                  <p className="timeline-date">{item.year}</p>
                  <p className="timeline-description">
                    {item.institution}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
}

export default Education;
