import React, { useState } from 'react';
import '../../css/MobileViewWorkExperience.css';
import WorkExperienceModal from '.././Modals/WorkExperienceModal'; // Make sure this component is defined

const MobileViewWorkExperience = ({ experiences }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [modalTechnologies, setModalTechnologies] = useState(null);

    const handleClick = (title, details, technologies) => {
        setModalTitle(title)
        setModalData(details);
        setModalTechnologies(technologies);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className="mobile-timeline-container">
                {experiences.map((exp, index) => (
                    <div className="mobile-timeline-item" key={index}>
                        <div
                            className="mobile-timeline-marker"
                            onClick={() => handleClick(exp.company + " | " + exp.role, exp.responsibilities, exp.technologies)}
                            title={exp.position}
                        >
                            <img src={require(`../../assets/icons/${exp.logo}`)} title="Click to view detailed role" alt={exp.company} />
                        </div>
                        <div className="mobile-timeline-content"
                            onClick={() => handleClick(exp.company + " | " + exp.role, exp.responsibilities, exp.technologies)}
                        >
                            <h3 className="mobile-timeline-title">{exp.position}</h3>
                            <p style={{ fontSize: '1rem' }} className="mobile-timeline-date">{exp.company}</p>
                            <p className="mobile-timeline-date">{exp.start}</p>
                            <div
                                className="mobile-timeline-description"
                            >
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {showModal && <WorkExperienceModal title={modalTitle} details={modalData} technologies={modalTechnologies} onClose={handleCloseModal} />}
        </>
    );
};

export default MobileViewWorkExperience;
